import React, { FC, useEffect, useState } from 'react';
import s from './CallbackForm.module.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { submitFeedback } from '../../store/slices/infoSlice';
import { TextField } from '@mui/material';
import Loader from '../Loader/Loader';
import SuccessModal from './SuccessModal/SuccessModal';
import { ReactComponent as Long_arrow } from './../../assets/icon/long_arrow.svg';

const CallbackForm: FC = () => {
    const dispatch = useAppDispatch();
    const { feedback_loading, feedback_error, feedback_success } = useAppSelector(state => state.info);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        order_details: ''
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(submitFeedback(formData));
        setFormData({
            name: '',
            email: '',
            order_details: ''
        });
    };

    useEffect(() => {
        const handleResize = () => setInnerWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const isFormValid = () => {
        return formData.name.trim() && formData.email.trim() && formData.order_details.trim();
    };



    return (
        <>
            {feedback_loading && <Loader />}
            <form className={s.form} onSubmit={handleSubmit}>
                <TextField
                    className={s.inputField}
                    required
                    id="name"
                    label="Имя"
                    variant="outlined"
                    value={formData.name}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 100 }}
                />
                <TextField
                    className={s.inputField}
                    required
                    type="text"
                    id="email"
                    label="Введите номер телефона"
                    variant="outlined"
                    value={formData.email}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 100 }}
                />
                <TextField
                    className={s.inputField}
                    required
                    id="order_details"
                    label={innerWidth < 767 ? 'Сообщение' : 'Какие модели и в каком количестве желаете заказать?'}
                    variant="outlined"
                    value={formData.order_details}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 100 }}
                />

                {feedback_error && <span className='error'>{feedback_error}</span>}
                <button
                    disabled={!isFormValid()}
                    type="submit" className={`${s.button} button`}>ОТПРАВИТЬ ЗАЯВКУ <Long_arrow /></button>
            </form>
            {feedback_success && <SuccessModal />}
        </>
    );
};

export default CallbackForm;
