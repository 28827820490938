import React, { ChangeEvent, Dispatch, FC, SetStateAction, useEffect } from 'react';
import s from './PlacingOrder.module.scss';
import { StateProps } from '../PreOrderProduct';
import { TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { submitPreOrderProduct } from '../../../store/slices/preOrderSlice';
import Loader from '../../../components/Loader/Loader';
import { scrollToTop } from '../../../utils';
import { ReactComponent as Long_arrow } from './../../../assets/icon/long_arrow.svg'

interface PlacingOrderProps {
    setState: React.Dispatch<React.SetStateAction<StateProps>>;
    state: StateProps;
    toggleSwitchComponents: () => void;
    setSuccess: Dispatch<SetStateAction<boolean>>;
}

const PlacingOrder: FC<PlacingOrderProps> = ({ setSuccess, setState, state, toggleSwitchComponents }) => {
    const dispatch = useAppDispatch();
    const { loading } = useAppSelector(state => state.preOrder);

    const renderColorOptions = () => {
        const maxVisibleColors = 5;
        const visibleColors = state.rows.slice(0, maxVisibleColors);
        const extraColorsCount = state.rows.length > maxVisibleColors ? state.rows.length - maxVisibleColors : 0;
        return (
            <>
                {visibleColors.map((row) => (
                    <span
                        key={row.id}
                        style={{
                            background: row.color,
                            left: `${visibleColors.indexOf(row) * 12}px`,
                        }}
                    ></span>
                ))}
                {extraColorsCount > 0 && (
                    <span
                        style={{
                            left: `${visibleColors.length * 12}px`,
                        }}
                    >
                        +{extraColorsCount}
                    </span>
                )}
            </>
        );
    };

    useEffect(() => {
        scrollToTop();
    }, []);

    const renderSizeOptions = () => {
        return state.rows.flatMap(row =>
            row.sizes.map((size) => (
                <span key={`${row.id}-${size.id}`}>
                    {size.size}/
                </span>
            ))
        );
    };

    const renderResultl = () => {
        const price = state.product_price ?? 0;
        const totalQuantity = state.total_quantity;
        const total = price * totalQuantity;
        return total;
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { id, value } = e.target;
        setState(prevData => ({
            ...prevData,
            [id]: value,
        }));
    };

    const fetchByData = async (event: React.FormEvent) => {
        event.preventDefault();
        const formattedData = {
            name: state.name,
            contact_method: state.contact_method,
            product_id: state.product_id,
            product_price: state.product_price,
            total_price: renderResultl(),
            total_quantity: state.total_quantity,
            colors: state.rows.map(row => ({
                color_id: row.color_id,
                color_quantity: row.color_quantity,
                sizes: row.sizes.map(size => ({
                    size: size.size,
                    quantity: size.quantity
                }))
            }))
        };
        try {
            const res = await dispatch(submitPreOrderProduct(formattedData)).unwrap();
            setSuccess(true);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            {loading && <Loader />}
            <div className={s.wrapper}>
                <div className={s.table_box}>
                    <ul>
                        <li>
                            <span>Товар</span>
                            <div>
                                <img className={s.img} src={state?.image} alt="img" />
                                <p>{state?.title}</p>
                            </div>
                        </li>
                        <li>
                            <span>Цена</span>
                            <div>
                                <p>от {state?.product_price} с</p>
                            </div>
                        </li>
                        <li>
                            <span>Цвет</span>
                            <div className={s.colorOption}>{renderColorOptions()}</div>
                        </li>
                        <li>
                            <span>Размер</span>
                            <div className={s.sizeOption}>
                                {renderSizeOptions()}
                            </div>
                        </li>
                        <li>
                            <span>Количество</span>
                            <div >
                                <p>{state?.total_quantity}</p>
                            </div>
                        </li>
                        <li>
                            <span>Итого</span>
                            <div>
                                <p>от {renderResultl()} c</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <form onSubmit={fetchByData}>
                    <TextField
                        className={s.inputField}
                        required
                        id="name"
                        label="Ваше имя (обязательно)"
                        variant="outlined"
                        inputProps={{ maxLength: 100 }}
                        value={state.name}
                        onChange={handleChange}
                    />
                    <TextField
                        className={s.inputField}
                        required
                        id="contact_method"
                        label="Введите номер телефона (обязательно)"
                        variant="outlined"
                        inputProps={{ maxLength: 100 }}
                        value={state.contact_method}
                        onChange={handleChange}
                    />
                    <p className={s.text}>*После оформления предзаказа, с вами свяжется менеджер для подтверждения предзаказа</p>
                    <button className={`${s.button} button`}>ОФОРМИТЬ ПРЕДЗАКАЗ<Long_arrow /></button>
                </form>
                <button className={`${s.buttonBack} button`} onClick={toggleSwitchComponents}>НАЗАД</button>
            </div>
        </>
    );
};

export default PlacingOrder;
