import React from 'react';
import s from './Header.module.scss'
import { Link, NavLink } from 'react-router-dom';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import logo from '../../assets/images/BIORO.png'
import { useAppSelector } from '../../store/hooks';
import { ReactComponent as WhatsAppIcon } from './../../assets/icon/whatsapp_icon.svg'
import { ReactComponent as InstagramIcon } from './../../assets/icon/instagram_icon.svg'
import { ReactComponent as MailIcon } from './../../assets/icon/mail_icon.svg'
import { ReactComponent as TelegramIcon } from './../../assets/icon/telegram_icon.svg'

const Header = () => {
    const { countInWhishlist } = useAppSelector(state => state.whishlist)
    const { info } = useAppSelector(state => state.info)

    return (
        <header>
            <div className={`${s.wrapper} container`}>
                <BurgerMenu />
                <nav>
                    <ul className={s.menu}>
                        <li><NavLink to="/about-us" className={({ isActive }) => isActive ? s.active : ''}> О нас</NavLink></li>
                        <li><NavLink to={'/reviews'} className={({ isActive }) => isActive ? s.active : ''}>Отзывы</NavLink></li>
                        <li><NavLink to={'/catalog'} className={({ isActive }) => isActive ? s.active : ''}>Каталог</NavLink></li>
                        <li><NavLink to={'/favorites'}
                            data-count={countInWhishlist}
                            className={({ isActive }) => isActive ? `${s.active} ${s.whish}` : s.whish}>Избранное</NavLink></li>
                    </ul>
                    <Link className={s.logo} to='/'>
                        <img src={logo} alt="logo" />
                        <p>
                            швейное производство №1
                        </p>
                    </Link>
                </nav>
                <ul className={s.menu}>
                    <li><NavLink
                        to={'/pre-order'}
                        className={({ isActive }) => `${isActive ? s.active : ''} ${s.anotherStyle}`}>
                        Узнайте стоимость заказа
                        <br />
                        БЕСПЛАТНО ЗА 1 МИНУТУ
                    </NavLink>
                    </li>
                    <ul className={s.sosial_icon}>
                        <li><a target='_blank' rel="noreferrer noopener" href={`https://wa.me/${info?.contacts.whatsapp}`}><WhatsAppIcon /></a></li>
                        <li><a target='_blank' rel="noreferrer noopener" href={info?.contacts.instagram}><InstagramIcon /></a></li>
                        <li><a target='_blank' rel="noreferrer noopener" href={`mailto:${info?.contacts.email}`}><MailIcon /></a></li>
                        <li><a target='_blank' rel="noreferrer noopener" href={`https://t.me/${info?.contacts.telegram}`}><TelegramIcon /></a></li>
                    </ul>
                </ul>
            </div>
        </header>
    );
};

export default Header;