import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IColors, IFaqs, IInfoAndHome, INewFeedback, IReviews } from '../types'
import { storesApi } from '../../axios'

type InfoState = {
    loading: boolean
    error: null | string
    info: null | IInfoAndHome
    reviews: null | IReviews[],
    faqs: null | IFaqs[]
    colors: null | IColors[]
    feedback_loading: boolean
    feedback_error: string | null
    feedback_success: boolean
    captcha: boolean
}

const initialState: InfoState = {
    error: null,
    loading: false,
    info: null,
    reviews: [],
    faqs: [],
    colors: [],
    feedback_loading: false,
    feedback_error: null,
    feedback_success: false,
    captcha: false
}

export const fetchByCompanyInfo = createAsyncThunk<IInfoAndHome, void, { rejectValue: string }>(
    'info/fetchByCompanyInfo',
    async (_, { rejectWithValue }) => {
        const res = await storesApi.getMainAndInfoCompany()

        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data as IInfoAndHome
    })


export const fetchByReviews = createAsyncThunk<IReviews[], void, { rejectValue: string }>(
    'info/fetchByReviews',
    async (_, { rejectWithValue }) => {
        const res = await storesApi.getReviews()

        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data as IReviews[];
    })


export const validateCaptcha = createAsyncThunk<string, string, { rejectValue: string }>(
    'info/validateCaptcha',
    async (token, { rejectWithValue }) => {
        try {
            const res = await storesApi.validateCaptcha(token);

            if (res.status !== 200) {
                return rejectWithValue('Server Error');
            }

            return res.data.message as string;
        } catch (error) {
            return rejectWithValue('Server Error');
        }
    }
);


export const submitFeedback = createAsyncThunk<string, INewFeedback, { rejectValue: string }>(
    'info/submitFeedback',
    async (data, { rejectWithValue }) => {
        try {
            const res = await storesApi.setNewFeedBack(data);
            if (res.status !== 201) {
                return rejectWithValue('Server Error');
            }
            return res.data.message as string;
        } catch (error) {
            return rejectWithValue('Server Error');
        }
    }
);



export const fetchByFaqs = createAsyncThunk<IFaqs[], void, { rejectValue: string }>(
    'info/fetchByFaqs',
    async (_, { rejectWithValue }) => {
        try {
            const res = await storesApi.getFaqs();

            if (res.status !== 200) {
                return rejectWithValue('Server Error');
            }

            return res.data as IFaqs[];
        } catch (error) {
            return rejectWithValue('Server Error');
        }
    }
);

export const fetchByColors = createAsyncThunk<IColors[], void, { rejectValue: string }>(
    'info/fetchByColors',
    async (_, { rejectWithValue }) => {
        try {
            const res = await storesApi.getColors();

            if (res.status !== 200) {
                return rejectWithValue('Server Error');
            }

            return res.data as IColors[];
        } catch (error) {
            return rejectWithValue('Server Error');
        }
    }
);



const infoSlice = createSlice({
    name: 'info',
    initialState,
    reducers: {
        toggleCaptcha(state) {
            state.captcha = false
        },
        toggleFeedbackSuccess(state) {
            state.feedback_success = false
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchByCompanyInfo.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(fetchByCompanyInfo.fulfilled, (state, action) => {
            state.info = action.payload
            state.loading = false
        })
        addCase(fetchByCompanyInfo.rejected, (state) => {
            state.loading = false
            state.error = 'Ошибка сервера!'
        })
        // ===========================================================
        addCase(fetchByFaqs.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(fetchByFaqs.fulfilled, (state, action) => {
            state.faqs = action.payload
            state.loading = false
        })
        addCase(fetchByFaqs.rejected, (state) => {
            state.loading = false
            state.error = 'Ошибка сервера!'
        })
        // ===========================================================
        addCase(fetchByColors.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(fetchByColors.fulfilled, (state, action) => {
            state.colors = action.payload
            state.loading = false
        })
        addCase(fetchByColors.rejected, (state) => {
            state.loading = false
            state.error = 'Ошибка сервера!'
        })
        // ===========================================================
        addCase(submitFeedback.pending, (state) => {
            state.feedback_loading = true
            state.feedback_error = null
        })
        addCase(submitFeedback.fulfilled, (state, action) => {
            if (action.payload.includes('successfully')) {
                state.feedback_loading = false
                state.feedback_success = true
            }
        })
        addCase(submitFeedback.rejected, (state) => {
            state.feedback_loading = false
            state.feedback_error = 'Ошибка сервера! Попробуйте позже.'
        })
        // ===========================================================
        addCase(validateCaptcha.pending, (state) => {
            state.feedback_loading = true
            state.feedback_error = null
        })
        addCase(validateCaptcha.fulfilled, (state, action) => {
            if (action.payload.includes('successful')) {
                state.feedback_loading = false
                state.captcha = true
            }
        })
        addCase(validateCaptcha.rejected, (state) => {
            state.feedback_loading = false
            state.feedback_error = 'Ошибка сервера! Попробуйте позже.'
        })
        // ===========================================================
        addCase(fetchByReviews.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(fetchByReviews.fulfilled, (state, action) => {
            state.reviews = action.payload
            state.loading = false
        })
        addCase(fetchByReviews.rejected, (state) => {
            state.loading = false
            state.error = 'Ошибка сервера!'
        })


    },
})

export const { toggleCaptcha, toggleFeedbackSuccess } = infoSlice.actions

export default infoSlice.reducer
